import {
  consoleKlickSignature,
  setStorageItem,
  syncCrossSiteLanguageSettings,
} from '@klickmarketing/react-components'
import { v4 as uuidv4 } from 'uuid'

import { removeTrailingSlash } from './src/common/utils'
import { getLocalizedRelativePath, LOCALES } from './languages'

export {
  WrapRootElement as wrapRootElement,
  WrapPageElement as wrapPageElement,
} from './provider-wrappers'

// Only print our signature to the console in prod
process.env.NODE_ENV === 'production' && consoleKlickSignature()

export const onClientEntry = () => {
  const uuid = uuidv4()
  const session_id = uuid.replace(/-/g, '')
  setStorageItem('session_id', session_id)
  syncCrossSiteLanguageSettings()

  const head = document.head
  const muiCommentNode = document.createComment(`mui-inject-first`)
  head.insertBefore(muiCommentNode, head.querySelector('style[data-styled]'))
}

/**
 * @see {@link https://github.com/KlickMarketing/klick-careers/issues/480}
 * @see {@link https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#shouldUpdateScroll}
 * @returns `false` if we're navigating to a new tab that has no anchor, otherwise returns `true` for default behavior.
 **/
export const shouldUpdateScroll = ({
  prevRouterProps,
  routerProps,
  ...rest
}) => {
  const homepagePaths = Object.values(LOCALES).map((locale) =>
    removeTrailingSlash(getLocalizedRelativePath('/', locale))
  )

  if (
    (removeTrailingSlash(routerProps.location.pathname)?.endsWith(
      '/discover'
    ) &&
      removeTrailingSlash(prevRouterProps?.location?.pathname)?.endsWith(
        '/discover'
      )) ||
    homepagePaths.includes(removeTrailingSlash(routerProps.location.pathname))
  ) {
    window.scrollTo(0, 0)
    return false
  }
  return !!(prevRouterProps || routerProps?.location?.hash)
}
