import React from 'react'
import {
  AccessibilityProvider,
  ConsentProvider,
  EnhancedProvider,
  IntroProvider,
  ModalProvider,
  ScrollProvider,
  ThemeProvider,
} from '@klickmarketing/react-components'
import CssBaseline from '@material-ui/core/CssBaseline'

import { AlgoliaSearchProvider } from './src/contexts/AlgoliaSearch'
import Auth0ProviderWithHistory from './src/contexts/Auth0ProviderWithHistory'
import AuthorizedApolloProvider from './src/contexts/AuthorizedApolloProvider'
import { QuickSearchStateProvider } from './src/contexts/QuickSearchStateProvider'
import { siteMetadata } from './config'

export const WrapRootElement = ({ element }) => {
  return (
    <QuickSearchStateProvider>
      <AlgoliaSearchProvider>
        <Auth0ProviderWithHistory>
          <AuthorizedApolloProvider>
            <ModalProvider>
              <EnhancedProvider>
                <ScrollProvider>
                  <AccessibilityProvider>
                    <ConsentProvider>
                      <ThemeProvider
                        brandName={siteMetadata.brandName}
                        themeType={siteMetadata.themeType}
                      >
                        <IntroProvider>{element}</IntroProvider>
                      </ThemeProvider>
                    </ConsentProvider>
                  </AccessibilityProvider>
                </ScrollProvider>
              </EnhancedProvider>
            </ModalProvider>
          </AuthorizedApolloProvider>
        </Auth0ProviderWithHistory>
      </AlgoliaSearchProvider>
    </QuickSearchStateProvider>
  )
}

export const WrapPageElement = ({ element }) => (
  <>
    <CssBaseline />
    {element}
  </>
)
