import React from 'react'
import algoliasearch from 'algoliasearch/lite'

import { ALGOLIA_APP_ID, ALGOLIA_SEARCH_API_KEY } from '../../config'

const AlgoliaContext = React.createContext()

const AlgoliaSearchProvider = ({ children }) => {
  const [client] = React.useState(
    algoliasearch(ALGOLIA_APP_ID, ALGOLIA_SEARCH_API_KEY)
  )
  return (
    <AlgoliaContext.Provider value={client}>{children}</AlgoliaContext.Provider>
  )
}

const useAlgoliaSearch = () => {
  const context = React.useContext(AlgoliaContext)
  if (context === undefined) {
    throw new Error(
      'useAlgoliaSearch must be used within a algoliaSearchPovider'
    )
  }
  return context
}

export { useAlgoliaSearch, AlgoliaSearchProvider }
