import React from 'react'
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react'
import { isBrowser, tracking } from '@klickmarketing/react-components'
import { navigate } from 'gatsby'

import { AUTH0_AUDIENCE, AUTH0_CLIENT_ID, AUTH0_DOMAIN } from '../../config'

const onRedirectCallback = (appState, setJustLoggedIn) => {
  console.log('onRedirectCallback', {
    appState,
    pathname: window.location.pathname,
  })
  navigate(appState?.returnTo || '/')
}

const Auth0ProviderWithHistory = ({ children }) => {
  return (
    <Auth0Provider
      domain={AUTH0_DOMAIN}
      clientId={AUTH0_CLIENT_ID}
      redirectUri={isBrowser && `${window.location.origin}/login-success`}
      onRedirectCallback={onRedirectCallback}
      audience={AUTH0_AUDIENCE}
    >
      <HubSpotIndentifier />
      {children}
    </Auth0Provider>
  )
}

const HubSpotIndentifier = () => {
  const { user, isAuthenticated } = useAuth0()
  React.useEffect(() => {
    if (isAuthenticated && user?.email) {
      tracking.identify({ email: user.email })
    }
  }, [user, isAuthenticated])
  return <></>
}

export default Auth0ProviderWithHistory
