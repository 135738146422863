import { parseISO } from 'date-fns'
export const CONTENTFUL_PREFIX = 'https://images.ctfassets.net/s5qqrp96y1p4/'

export const generatePostUri = ({ type, slug }) => {
  const baseUrls = {
    ArticlePost: 'articles',
    VideoPost: 'videos',
    Collection: 'collections',
  }
  return `/${baseUrls[type]}/${slug}`
}

export const formatPublishDate = (publishDate, locale = 'en-US') => {
  const inputDate =
    typeof publishDate == 'number'
      ? new Date(publishDate).toISOString()
      : publishDate

  return new Date(parseISO(inputDate.split('T')[0])).toLocaleDateString(
    locale,
    {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    }
  )
}

export function getParamFromUrl(paramName) {
  if (typeof window === 'undefined' || !window?.location?.search) return

  const params = new URLSearchParams(window?.location?.search)
  const param = params.get(paramName)

  return parseParam(param)
}

// Note: probably should just use QS package or something for the parsing.
function parseParam(param) {
  if (param === 'true') {
    return true
  }
  if (param === 'false') {
    return false
  }

  return param
}

export const getQueryStringFromObject = (obj) => {
  const params = new URLSearchParams('')
  const pairs = Object.entries(obj).filter(([_, value]) => Boolean(value))
  pairs.forEach((p) => params.append(p[0], p[1]))
  const qs = params.toString()
  return qs.length > 0 ? `?${qs}` : qs
}

const doubleEncodeURIComponent = (str) => {
  return encodeURIComponent(encodeURIComponent(str)).replace(/%2520/g, '+')
}

export const getSocialThumbPath = (mediaItem, options) => {
  if (!mediaItem) return
  const title = mediaItem.title && doubleEncodeURIComponent(mediaItem.title)
  const date = mediaItem.publishDate && formatPublishDate(mediaItem.publishDate)
  const image = mediaItem.heroImage
    ? mediaItem.heroImage?.image?.url
    : mediaItem.videoAsset
    ? mediaItem.videoAsset?.thumbnail?.url
    : mediaItem?.image?.image?.url
  const persons = mediaItem?.personsCollection?.items
    ?.map((person) => person.name)
    .join(', ')

  const qs = getQueryStringFromObject({
    d: date,
    i: image.replace(CONTENTFUL_PREFIX, ''),
    p: persons,
    ...options,
  })
  const socialThumbPath = `/social/${title}.jpeg${qs}`
  return socialThumbPath
}

export function removeTrailingSlash(str) {
  return str?.endsWith('/') ? str?.slice(0, -1) : str
}
