import React from 'react'
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { useAuth0 } from '@auth0/auth0-react'
import { isBrowser } from '@klickmarketing/react-components'

const PREVIEW_QUERY = 'preview'

const AuthorizedApolloProvider = ({ children }) => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0()

  const apolloClient = React.useMemo(() => {
    const query = isBrowser && window.location.search
    const urlParams = new URLSearchParams(query)
    const previewValue = urlParams.get(PREVIEW_QUERY)

    const preview = previewValue ? `?preview=${previewValue}` : ''
    const httpLink = createHttpLink({
      uri: `/api${preview}`,
    })

    const authLink = setContext(async (_, { headers }) => {
      const token = isAuthenticated
        ? await getAccessTokenSilently()
        : 'anonymous'

      return {
        headers: {
          ...headers,
          authorization: `Bearer ${token}`,
        },
      }
    })

    return new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache(),
      connectToDevTools: true,
    })
  }, [getAccessTokenSilently, isAuthenticated])

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
}

export default AuthorizedApolloProvider
