import React from 'react'

const QuickSearchStateContext = React.createContext()

const QuickSearchStateProvider = ({ children }) => {
  const [open, setOpen] = React.useState(false)

  return (
    <QuickSearchStateContext.Provider value={{ open, setOpen }}>
      {children}
    </QuickSearchStateContext.Provider>
  )
}

const useQuickSearchState = () => {
  const context = React.useContext(QuickSearchStateContext)
  if (context === undefined) {
    throw new Error(
      'useQuickSearchState must be used within a QuickSearchStateProvider'
    )
  }
  return context
}

export { QuickSearchStateProvider, useQuickSearchState }
