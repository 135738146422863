exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-articles-js": () => import("./../../../src/pages/articles/[...].js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-collections-index-js": () => import("./../../../src/pages/collections/index.js" /* webpackChunkName: "component---src-pages-collections-index-js" */),
  "component---src-pages-collections-js": () => import("./../../../src/pages/collections/[...].js" /* webpackChunkName: "component---src-pages-collections-js" */),
  "component---src-pages-discover-js": () => import("./../../../src/pages/discover.js" /* webpackChunkName: "component---src-pages-discover-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-success-js": () => import("./../../../src/pages/login-success.js" /* webpackChunkName: "component---src-pages-login-success-js" */),
  "component---src-pages-logout-success-js": () => import("./../../../src/pages/logout-success.js" /* webpackChunkName: "component---src-pages-logout-success-js" */),
  "component---src-pages-post-password-reset-js": () => import("./../../../src/pages/post-password-reset.js" /* webpackChunkName: "component---src-pages-post-password-reset-js" */),
  "component---src-pages-social-js": () => import("./../../../src/pages/social.js" /* webpackChunkName: "component---src-pages-social-js" */),
  "component---src-pages-videos-js": () => import("./../../../src/pages/videos/[...].js" /* webpackChunkName: "component---src-pages-videos-js" */)
}

