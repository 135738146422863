// module.exports = {
//   languages: ['en-US', 'fr-FR', 'es-AR', 'pt-BR'],
//   defaultLanguage: 'en-US',
// }

const LOCALES = {
  'en-US': 'en-US',
  de: 'de',
  es: 'es',
  ja: 'ja',
  'pt-BR': 'pt-BR',
}

const DEFAULT_LOCALE = LOCALES['en-US']

const getAlgoliaIndexNameByLocale = (env, locale) => {
  const indexLookupMap = {
    dev: {
      [LOCALES['en-US']]: 'dev_idxv2',
      [LOCALES['de']]: 'dev_de_idxv2',
      [LOCALES['es']]: 'dev_es_idxv2',
      [LOCALES['ja']]: 'dev_ja_idxv2',
      [LOCALES['pt-BR']]: 'dev_pt-BR_idxv2',
    },
    prod: {
      [LOCALES['en-US']]: 'prod_idxv2',
      [LOCALES['de']]: 'prod_de_idxv2',
      [LOCALES['es']]: 'prod_es_idxv2',
      [LOCALES['ja']]: 'prod_ja_idxv2',
      [LOCALES['pt-BR']]: 'prod_pt-BR_idxv2',
    },
  }

  return indexLookupMap[env]?.[locale] || indexLookupMap[env]?.[DEFAULT_LOCALE]
}

function getIsLocaleValid(locale) {
  const validLocale = LOCALES[locale]

  if (!validLocale) return false

  return true
}

function getLocalizedRelativePath(path, language) {
  if (!getIsLocaleValid(language)) return path

  if (language === DEFAULT_LOCALE) return path

  return `/${language}${path}`
}

module.exports = {
  LOCALES,
  DEFAULT_LOCALE,
  getAlgoliaIndexNameByLocale,
  getLocalizedRelativePath,
}
