// These must NOT be destructured or it will not work
export const SITE_URL = process.env.GATSBY_SITE_URL
export const STACKNAME = process.env.GATSBY_STACKNAME
export const GIT_SHORT = process.env.GATSBY_GIT_SHORT

export const ALGOLIA_SEARCH_API_KEY = process.env.GATSBY_ALGOLIA_SEARCH_API_KEY
export const ALGOLIA_ENV_PREFIX = process.env.GATSBY_ALGOLIA_ENV_PREFIX
export const ALGOLIA_APP_ID = process.env.GATSBY_ALGOLIA_APP_ID

export const AUTH0_DOMAIN = process.env.GATSBY_AUTH0_DOMAIN
export const AUTH0_CLIENT_ID = process.env.GATSBY_AUTH0_CLIENT_ID
export const AUTH0_AUDIENCE = process.env.GATSBY_AUTH0_AUDIENCE

export const HUBSPOT_ID = process.env.GATSBY_HUBSPOT_ID

export const siteMetadata = {
  siteTitle: 'Klick Ideas Exchange',
  description:
    'Innovation is collaboration, and Klick Ideas Exchange(IDX) is our way of sharing that vision with you.',
  siteUrl: SITE_URL,
  author: `@klickhealth`,
  brandName: 'IDX',
  themeType: 'onWhite',
  stackname: STACKNAME,
  gitVersion: GIT_SHORT,
}

// Floodlight tags taken from: https://docs.google.com/spreadsheets/d/1B5Tf-6Gl2ZkQheQvemK6GgehM9Zc2_umMFVmKPuAh5M/edit#gid=296964555
export const IDX_FLOODLIGHT_TAGS = {
  // Fire when the user clicks the "Sign Up" CTA
  CLICK_SIGNUP_NAV_BUTTON: 'event0/klick041+per_session',

  // Fire when the user clicks the "Log In" CTA
  CLICK_LOGIN_NAV_BUTTON: 'event0/klick042+per_session',

  // Fire when the user clicks "Collections" in the navigation bar
  CLICK_COLLECTIONS_NAV_BUTTON: 'event0/klick04+per_session',

  // Fire when the user clicks "Discover" in the navigation bar
  CLICK_DISCOVER_NAV_BUTTON: 'event0/klick03_+per_session',

  // Fire when any of the documents are downloaded
  CLICK_POV_DOWNLOAD_BUTTON: 'event0/klick045+per_session',

  // Fire when any of the Article pages load
  PAGE_LOAD_ARTICLE: 'event0/klick044+per_session',

  // Fire when any of the Collections pages load
  PAGE_LOAD_COLLECTION: 'event0/klick043+per_session',

  // Fire when the homepage loads
  PAGE_LOAD_HOMEPAGE: 'event0/klick03z+per_session',

  // Fire when any of the video pages load
  PAGE_LOAD_VIDEO: 'event0/klick046+per_session',

  // Fire when the user clicks to play any video and watches more than 0%
  PLAY_VIDEO_0_PERCENT: 'event0/klick047+per_session',

  // Fire when any of the videos are watched more than 50%
  PLAY_VIDEO_50_PERCENT: 'event0/klick048+per_session',
}
